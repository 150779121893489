import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "./contactForm"
const Download = () => {
  const source = typeof window !== 'undefined' ? window.location.href : '';
  return (
    <section id="pobierz-ebook" className="bg-light-pink">
        <div className="max-w-7xl mx-auto grid md:grid-cols-3 gap-24">
            <div className="z-10 relative col-span-2 flex flex-col justify-end mr-24 text-lg">
                <p className="text-purple mb-12">
                Jak rekrutować specjalistów i menedżerów? <br/>
                Metawersum w rekrutacji – najbliższy trend czy daleka przyszłość? <br/>
                Crowdstaffing, contracting, RPO – jak wygląda popularność tych usług rekrutacyjnych?
                </p> 
                <p className="text-purple font-bold mb-10">Pobierz raport Antal wydany we współpracy z ReX „Trendy w zakresie rekrutacji specjalistów i menedżerów” i poznaj najnowsze wyniki badania, a także komentarze ekspertów!</p>
            </div>
            <div className="flex justify-center items-center px-12">
                <StaticImage className="" src={'../images/Rex-mockup-klienci.png'} />
            </div>
        </div>
        <div className="max-w-7xl mx-auto">
            <ContactForm source={source} />
        </div>
        
    </section>
  )
}

export default Download