import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import LogoRex from "../images/logo-rex.svg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Examples = () => {
    let settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
          responsive: [
              {
                  breakpoint: 800,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                  },
              },
              ]
    };


  return (
    <section  className="examples relative pt-52 pb-10 bg-center -mt-40" id="realizacje">
        <div className="absolute bg-light-pink w-full left-0 h-1/2 bottom-0">

        </div>
        <div className="max-w-7xl mx-auto mt-40 md:mt-20 ">
            <h2 className="text-white font-bold text-3xl mb-12" >Przykłady<br/> zrealizowanych projektów</h2>
            <p className="text-white mb-0 "  >ReX Rekruterzy realizują nawet najtrudniejsze procesy rekrutacyjne.<br/> 
            W naszej społeczności są eksperci specjalizujący się we wszystkich branżach. Znajdziemy dla Ciebie pracownika<br/> 
            niezależnie od tego, czy potrzebujesz do zespołu 1, 10 czy 100 osób.</p>
            <Slider {...settings} className="relative -mx-10" >

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../images/examples/monika-k.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Młodsza księgowa</h3>
                        <p className="text-xs mb-6">Odpowiada za comiesięczne sprawozdania finansowe, rozliczanie z ZUS, GUS i Urzędem Skarbowym, listy płac, rozliczanie pracowników, archiwizacja dokumentacji, praca z programem Płatnik oraz Progmann – Kadry, Przelewy.</p>
                        <p className="text-xs font-bold">Kandydatów na tym stanowisku pozyskujemy najczęściej w Poznaniu, Wrocławiu, Warszawie za ok. 7 000 zł.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Monika - Młodsza księgowa - Cena rekrutacji - 7000 zł</h3>
                        <p className="text-sm">Prowadzenie księgowości oraz kadr spółek na pełnej, jak i uproszczonej ksiegowości.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../images/examples/anna.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Anna, CS with Slovenian</h3>
                        <p className="text-xs mb-6">Odpowiada za kompleksową obsługę zamówień i reklamacji sklepu internetowego. Telefoniczna i mailowa obsługa Klienta słoweńskiego, doradztwo w zakresie oferowanego produktu.</p>
                        <p className="text-xs font-bold">Kandydatów na tym stanowisku pozyskujemy najczęściej w Warszawie za ok. 8 500 zł.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Anna, CS with Slovenian - Cena rekrutacji - 8500 zł</h3>
                        <p className="text-sm">Kontakt mailowy i telefoniczny z klientami, którzy zakupili produkt klienta.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../images/examples/urszula.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">People Advisor </h3>
                        <p className="text-xs mb-6">Inaczej specjalista ds. kadr. Opowiada za cały cykl zatrudnienia pracownika oraz doradztwo w zakresie prawa pracy.</p>
                        <p className="text-xs font-bold">Kandydatów na tym stanowisku pozyskujemy najczęściej w Warszawie za ok. 17 000 zł.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Urszula – People Advisor – Cena rekrutacji: 17 000 zł.</h3>
                        <p className="text-sm">Wsparcie pracowników w sprawach administracyjno-kadrowych. </p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../images/examples/adam.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Przedstawiciel Handlowy</h3>
                        <p className="text-xs mb-6">Odpowiada za realizację targetu, opieka nad obecnymi klientami i często pozyskiwanie nowych klientów.</p>
                        <p className="text-xs font-bold">Kandydatów na tym stanowisku pozyskujemy w całej Polsce za ok. 10 000 zł.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Adam - Przedstawiciel Handlowy - Cena rekrutacji: 10 000 zł</h3>
                        <p className="text-sm">Sprzedaż usług firmy i reprezentowanie jej w kontaktach z klientami.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../images/examples/ewa.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">CS with German</h3>
                        <p className="text-xs mb-6">Odpowiada za wprowadzanie danych odnośnie do zamówień do SAP, rozwiązywanie bieżących problemów klientów, informowanie klientów o statusie ich zamówień.</p>
                        <p className="text-xs font-bold">Kandydatów na tym stanowisku pozyskujemy w całej Polsce za ok. 8 000 zł.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Ewa, CS with German - Cena rekrutacji - 8000 zł</h3>
                        <p className="text-sm">Kontakt telefoniczny i mailowy z klientami biznesowymi w sprawie zamówień.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../images/examples/tomek.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Customer Quality Engineer</h3>
                        <p className="text-xs mb-6">Odpowiada za nadzór nad spełnianiem wymagań klienta w organizacji. Zarządzanie reklamacjami od klienta (raporty 8D + śledzenie działań) Sporządzanie analiz i raportów jakościowych. Przygotowanie PPAP dla zmian w produkcji seryjnej.</p>
                        <p className="text-xs font-bold">Kandydatów na tym stanowisku pozyskujemy w całej Polsce za ok. 12 000 zł.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Tomek, Customer Quality Engineer -  Cena rekrutacji - 12 000 zł</h3>
                        <p className="text-sm">Pierwsza linia obrony przed reklamacjami od Klienta.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../images/examples/monika-c.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">C# Developer</h3>
                        <p className="text-xs mb-6">"Tworzenie oprogramowania do samodzielnej aktualizacji danych.
                        Wprowadzanie aktualizacji oprogramowania zgodnie z zapotrzebowaniem biznesu.
                        Kontakt z biznesem."</p>
                        <p className="text-xs font-bold">Kandydatów na tym stanowisku pozyskujemy w całej Polsce za ok. 12 000 zł.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Monika - C# Developer - Cena rekrutacji  - 25 000 zł</h3>
                        <p className="text-sm">Budowa programu obliczeniowego do mechanicznych i hydraulicznych obciążeń, które panują o otworze wiertniczym.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../images/accent.png'} />
                </div>
            </div>

                
            </Slider>   
           
        </div>
    </section>    
  )
}

export default Examples