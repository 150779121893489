import * as React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const FaqItems = [
    {
        question: "Kim jest ReX Rekruter?",
        answer: 'To profesjonalny, samodzielny rekruter-freelancer lub firma rekrutacyjna. ReX Rekruterzy przechodzą proces selekcji weryfikacji i szkoleń, aby móc zostać naszymi Partnerami. Po podpisaniu umowy i egzaminach z zakresu procesowania kandydatów oraz RODO Rex Rekruterzy wspierają procesy rekrutacyjne na platformie crowdstaffingowej ReX.',
    },
    {
        question: "Kim jest ReX Partner?",
        answer: 'Rex Partner i ReX Rekruter to często określenia tożsame jednak w umowach i w systemie rozróżniamy ich na osobę lub firmę (ReX Partner), która jest stroną umowy, oraz na rekrutera (ReX Rekruter), który faktycznie realizuje rekrutację.',
    },
    {
        question: "Kim jest konsultant?",
        answer: 'Konsultant to pracownik firmy Antal, który odpowiada za procesowanie kandydata zgodnie z zawartą z klientem umową.',
    },
    {
        question: "Kim jest ReX Leader?",
        answer: 'REX Leader to osoba, która zarządza projektami z obszaru danej specjalizacji. Po stronie klienta odpowiada za komunikację i odpowiedni dobór Rex Rekruterów do realizacji projektu. Po stronie platformy ma za zadanie wspierać ReX Rekruterów i odpowiednio rekomendować im projekty zgodne z ich profilem doświadczeń.',
    },
    {
        question: "Kim jest Key Account Manager?",
        answer: 'Key Account Manager (KAM) jest ekspertem w dziedzinie rekrutacji w danym obszarze, osobą wyznaczoną do obsługi klienta i kandydata w ramach realizowanego zlecenia, która za dodatkową opłatą świadczy usługę consultingu, doradztwa, weryfikacji zgłoszeń oraz koordynacji współpracy na rzecz danego klienta.',
    },
    {
        question: "Crowdstaffing - jak to działa?",
        answer: 'Zadaniem tłumu (crowd) jest odpowiedzieć na potrzeby jednostki czy firmy. Dzięki temu, że kilka wyspecjalizowanych osób poszukuje kandydatów w rozproszeniu geograficznym z wykorzystaniem różnych narzędzi i różnych baz danych jednocześnie, mogą szybko i skutecznie zrealizować rekrutację.',
    },
    {
        question: "Jak wygląda proces składania zlecenia?",
        answer: 'Gdy otrzymamy opis stanowiska, jesteśmy w stanie przedstawić wycenę. Po jej akceptacji po stronie klienta wypełniony przez nas dokument zlecenia jest przesyłany za pośrednictwem portalu podpisów elektronicznych do akceptacji obu stron.',
    },
    {
        question: "Czy muszę sie rejestrować na platformie?",
        answer: 'Nie. Zapytanie ofertowe, opis stanowiska i wszelkie inne dane można przesłać za pomocą formularza lub na e-mail karolina@recruitmentexchange.pl',
    },
    {
        question: "Co otrzymam?",
        answer: 'Klient otrzyma profile wyselekcjonowanych kandydatów zawierające dane kontaktowe, wymagania finansowe, okres wypowiedzenia, preferowaną formę współpracy danego kandydata, jego lokalizację oraz opisową rekomendację rekrutera wraz z załączonym CV. Rekomendowani kandydaci to tylko osoby zainteresowane rozmową na temat danej oferty, które są zaznajomione ze stanowiskiem.',
    },
    {
        question: "Ile to kosztuje? Kiedy następuje płatność?",
        answer: 'Kwota za sukces jest niezmienna bez względu na wynagrodzenie kandydata. Szacunkową wycenę można sprawdzić u nas na stronie, natomiast weryfikujemy ją w kontekście obecnej sytuacji rynkowej, potencjału kandydatów i adekwatności wymagań do potencjału zatrudnienia. Fakturę wystawiamy w dniu rozpoczęcia pracy przez kandydata z 14-dniowym terminem płatność.',
    },
    
];

const Faq = () => {
  return (
      <div className="overflow-hidden">
    <section id="faq" className="faq pt-0 pb-24 bg-gradient-to-br from-purple to-teal">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl text-center text-white font-bold mb-20">Najczęściej zadawane pytania</h2>
        <Accordion allowZeroExpanded="true">
            {FaqItems.map((faqItem) => (
        <AccordionItem className="mb-10">
          <AccordionItemHeading className="bg-white px-8 py-5 pt-6 text-purple shadow-xl relative z-10">
            <AccordionItemButton className="faq-button relative">
              {faqItem.question}
                <div className="arrow border-b-purple border-l-purple w-3 h-3 absolute right-0 top-0 border-[0.35rem] border-r-transparent border-t-transparent -rotate-45 transition-all"></div>
            </AccordionItemButton> 
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white px-8 py-8  text-dark-gray text-sm">
            {faqItem.answer}
          </AccordionItemPanel>
        </AccordionItem>
            ))}
            <div className="mb-10">
                <div className="bg-white px-8 py-5 pt-6 text-purple shadow-xl relative z-10">
                    <div className="relative flex flex-col md:flex-row justify-between items-center">
                        <h3 className="text-2xl basis-1 mr-4">Masz&nbsp;pytanie?</h3>
                        <div className="bg-gradient-to-r from-purple to-teal basis-full h-[2px] my-4"></div>
                        <a href="mailto:robert.nawrot@recruitmentexchange.pl" className="ml-4 basis-1 bg-teal text-dark-gray text-sm font-bold  px-24 pt-3  py-2 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple">
                            Napisz
                        </a>
                    </div>
                </div>
            </div>

        
      </Accordion>
      </div>
    </section>
      </div>
  )}

  export default Faq