import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

const API_PATH = "/api-mail.php"
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  surname: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  email: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .email("Nieprawidłowy format adresu e-mail")
    .required("Pole wymagane"),
  phone: Yup.string()
    .min(5, "Zawartość pola jest zbyt krótka")
    .max(15, "Zawartość pola jest zbyt długa"),
  privacyEbook: Yup.bool().oneOf([true], "Wymagana zgoda."),
})

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      surname: "",
      email: "",
      company: "",
      position: "",
      phone: "",
      mailSent: false,
      error: null,
      source: this.props.source,
    }
  }
  handleFormSubmit = e => {
    // e.preventDefault()
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
        })
        window.fbq('track', 'Lead');
        window.dataLayer.push({
          event: "conversion",
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }


  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: "",
            surname: "",
            email: "",
            company: "",
            position: "",
            phone: "",
            privacyEbook: false,
            source: this.state.source,
          }}
          validationSchema={ValidationSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <Form className="">
              <div className="grid md:grid-cols-3 gap-5 my-10">
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none shadow-md" type="text" name="name" placeholder="Imię*"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="name"
                      component="span"
                    />
                </div>
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none" type="text" name="surname" placeholder="Nazwisko*"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="surname"
                      component="span"
                    />
                </div>
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none" type="text" name="email" placeholder="Służbowy adres e-mail*"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="email"
                      component="span"
                    />
                </div>
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none" type="text" name="company" placeholder="Firma"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="company"
                      component="span"
                    />
                </div>
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none" type="text" name="position" placeholder="Stanowisko"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="position"
                      component="span"
                    />
                </div>
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none" type="text" name="phone" placeholder="Telefon"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="phone"
                      component="span"
                    />
                </div>
                </div>
                <div className="privacy-policies">
                <div>
                  <label
                    className="text-dark-gray relative pl-10 block text-sm mb-5"
                    htmlFor="privacyEbook"
                  >
                    Wyrażam zgodę na przetwarzanie przez Antal Spółka z o.o. z siedzibą w Warszawie (02-566), ul. Puławska 2 moich danych osobowych w celach marketingowych za pośrednictwem środków komunikacji elektronicznej (mailing, telefon). Zgodę można wycofać w dowolnym momencie.*
                  <Field
                    type="checkbox"
                    id="privacyEbook"
                    name="privacyEbook"
                    className="absolute left-0 top-0"
                  />
                    <ErrorMessage
                      className="error-msg text-xs"
                      name="privacyEbook"
                      component="span"
                    />
                  </label>
                </div>
                <Field
                        type="hidden"
                        id="source2"
                        name="source"
                        value={this.props.source}
                      />
                <div className="text-dark-gray relative pl-10 block text-sm">
                    Obligatoryjne
                    <div className="absolute left-0 top-0 text-xl">*</div>
                </div>
              </div>
              <div className="flex justify-center py-10">
              <button
                className="bg-teal text-dark-gray text-sm font-bold w-80 px-10 pt-4  py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple"
                type="submit"
                disabled={isSubmitting}
              >
                Pobieram ebook
              </button>
              </div>
              
            </Form>
          )}
        </Formik>
      )
    } else {
      return (
        <form className="py-24 text-center text-dark-gray">
          <h3 className="text-3xl mb-5">Dziękujemy!</h3>
          <p className="text-xl mb-12">Wiadomość została wysłana.</p>
          <a href="/static/Antal_Raport_Trendy_2022_PL_v01_compressed.pdf" className="bg-teal text-dark-gray text-sm font-bold w-80 px-10 pt-4  py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple">
                Pobierz plik
              </a>
        </form>
      )
    }
  }
}
