import * as React from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const TestimonialsArray = [
    {
        content: "Na platformę ReX trafiłem na chwilę, ale zostałem na dłużej. Moja przygoda trwa już ponad pół roku. Sporo czasu, w którym mogłem brać udział w licznych projektach z różnych branż. Świetne doświadczenia, doskonała współpraca i komunikacja z ReX Leaderami!",
        author: 'Mateusz Biegała',
        authorBio: ""
    },
    {
        content: "Współpraca z platformą ReX daje mi to, czego oczekuje w partnerskiej relacji - wolność. Pracuję skąd chce, gdzie chcę i jak chcę. Platforma ReX jest dla mnie, ponieważ sama wybieram sobie ciekawe projekty, od razu widzę konkretne informacje o wymaganiach Klienta, mam jasną wycenę projektu, a fakturę zapłaconą w terminie i bez problemów komunikacyjnych. Mogę wybrać branżę i rekrutować w różnych obszarach, aby zdobywać nowe doświadczenia  i urozmaicać sobie pracę. ReX Leaderzy są gotowi do współpracy, otwarci, profesjonalni. Praca, która jest pasja. Pasja, która jest praca. Polecam!",
        author: 'Monika Witkowska',
        authorBio: ""
    },
    {
        content: "Z platformą ReX jestem związany od jesieni 2020 roku. Dzięki niej mam możliwość wyboru interesujących mnie projektów w określonych obszarach. Wykorzystując w tej pracy posiadane wcześniej doświadczenia i umiejętności zyskałem wiele nowych. Platforma ReX stwarza więc możliwości rozwoju, które należy tylko właściwie wykorzystać. Ponadto współpraca z profesjonalnym zespołem w niepowtarzalnej atmosferze daje wiele satysfakcji i zadowolenia. Polecam!",
        author: 'Wojciech Chróściński',
        authorBio: ""
    },
    {
        content: "Polecam współpracę w ramach projektu ReX Partner. Transparentne warunki, projekty realne do zamknięcia, pełne wsparcie ze strony ReX Leadera, a do tego atrakcyjne stawki i możliwość dostosowania godzin do innych aktywności; to główne ale nie jedyne atuty tego programu. ",
        author: 'Michał Magowski',
        authorBio: ""
    },
    // {
    //     content: "Jako rekruter działam od  6 miesięcy. Pomimo ponad 20 letniego i bogatego doświadczenia w strukturach HR, należało nauczyć się podstawowych narzędzi. Portale społecznościowe, oprogramowanie rekruterskie, bezcenne szkolenia i wsparcia liderów – okazały się ważną podstawą pracy. Po tych 6  miesiącach coraz płynniej dociera się do kandydatów, korzysta z różnych narzędzi ich zdobywania i poznaje fantastyczne osoby. Mam nadzieję, ze czasy kiedy „przyjdzie” wynagrodzenie za pierwsze zatrudnienie wkrótce przede mną. Na pewno dużym utrudnieniem jest łączenie pracy stałej z pracą freelancera. Mocno należy rozplanować harmonogram dnia i bardzo często, szybko działać. W takich sytuacjach zmodyfikowałabym pewne „bezwzględne” procedury rekrutacyjne. Tak, zwyczajnie  dla wsparcia pracujących rekruterów i dania im szansy na realizację projektów. Takie działania mogą zdecydowanie podnieść jakość pracy i opinii nt. firmy. Dlaczego pomimo braku wynagrodzenia nie rezygnuję z freelancera?  - jeszcze :) Bezcenne jest dla mnie zdobycie solidnego doświadczenia. Ciekawość świata i ludzi to kolejny ważny aspekt. Kreatywność w docieraniu do przyszłego pracownika okazała się bezcenna umiejętnością. Najważniejsze są jednak dla mnie rozmowy z kandydatami, nawiązywanie nowych, cennych kontaktów i podziękowania od kandydatów, nawet jeśli nie zostali zatrudnieni. To ogromna satysfakcja i sens uważności rekrutera.",
    //     author: 'Pozdrawiam, Eliza ',
    //     authorBio: ""
    // },
    
]
const Testimonials = () => {
    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ]
    };
  return (
    <section  className="relative overflow-visible object-none pt-24 z-10">
            <div className="overflow-hidden pb-52">
                <div className="max-w-7xl mx-auto">
            <Slider {...settings} className="testimonials-slider overflow-visible relative -mx-5">
                {TestimonialsArray.map((testimonial) => (
                <div className="px-5">
                <div className="min-h-[420px] single-slide-inner bg-teal text-dark-gray p-10 border-4 border-purple transition-colors flex flex-col justify-between">
                    <p className="mb-10">{testimonial.content}</p>
                    <div>
                    <p className="font-bold text-right justify-self-end self-end">{testimonial.author}</p>
                    <p className="text-right justify-self-end self-end">{testimonial.authorBio}</p>
                    </div>
                </div>
                </div>    
                ))}
            </Slider>
            </div>
        </div>
    </section>
        
  )
}

export default Testimonials;