import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HowWeWork = () => {
  return (
    <section id="jak-dzialamy" className="relative overflow-visible object-none py-24 ">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-center text-3xl font-bold text-dark-gray mb-20">Jak <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">działamy</span>?</h2>
            <div className="grid lg:grid-cols-3 gap-20">
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">1</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Zgłoszenie projektu<br/> przez klienta</h3>
                    <p className="font-light text-dark-gray text-md">Dbamy o Twój czas, więc nie musisz nigdzie się rejestrować. Wystarczy, że prześlesz nam opis stanowiska i odpowiesz na kilka prostych pytań.</p>
                </div>
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">2</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Dobór rekruterów i natychmiastowy<br/> start działań</h3>
                    <p className="font-light text-dark-gray text-md">Pracują dla Ciebie wykwalifikowani ReX Rekruterzy, którzy znają specyfikę Twojej branży i lokalizację, dlatego zapewnią dopasowanych kandydatów. Rekruterzy od razu przystępują do działania.</p>
                </div>
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">3</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Dostarczenie kandydatów<br/> i rozliczenie projektu</h3>
                    <p className="font-light text-dark-gray text-md">Już po kilku dniach otrzymujesz pierwsze CV dopasowanych kandydatów. Płacisz nam tylko wtedy, gdy zatrudnisz zarekomendowanego kandydata. Warunki współpracy są transparentne i nie ponosisz żadnych ukrytych kosztów.</p>
                </div>
            </div>
            
           
        </div>
        
    </section>
  )
}

export default HowWeWork