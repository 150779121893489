import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PriceComparison = () => {

    const [salary, setSalary] = React.useState(0);
    const [cost, setCost] = React.useState(5000);

    // const calculateCost = (salary) => {
    //     let cost = salary * 12/10;
    //     if (cost <= 5000) {
    //         cost = 5000;
    //     }
    //     setCost(cost);
    // }


  return (
    <section className="py-24">
        <div className="max-w-7xl mx-auto shadow-2xl p-20  relative">
            <StaticImage src={'../images/price-comparison-bg.png'} className="absolute inset-0 " />
        <div  className="relative z-10 md:px-40 "  id="koszt-rekrutacji">
            <h2 className="text-center text-3xl font-bold text-dark-gray mb-20">Poznaj koszt <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">rekrutacji</span></h2>
            <div className="text-center mt-6 mb-10">
                <h3 className="text-dark-gray text-2xl font-bold text-center mb-5">Sprawdź orientacyjny koszt</h3>
                <p className="text-md mb-10">Miesięczne wynagrodzenie brutto:</p>

                <input className="mb-20 border-b outline-none bg-transparent text-center text-xl" type="number" value={salary} onChange={function(e) { setSalary(e.target.value);   }} /> zł
                <p className="text-md mb-10">Orientacyjna cena rekrutacji:</p>

                <div className="text-purple mb-10 font-bold">{((salary * 12)*0.12 <= 5000) ? 5000 : Math.ceil(((salary * 12)*0.12)/100)*100 } zł</div>

                <a href="/#top" className="bg-teal px-10 py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline-block text-center hover:bg-purple hover:text-white hover:shadow-purple mb-20">Uzyskaj szczegółową wycenę</a>
                </div>
            <h3   className="text-dark-gray text-2xl font-bold text-center mb-16">Porównanie modeli rekrutacyjnych</h3>
            <table className="price-comparison-table w-full mb-2">
                <tbody>
                <tr className="text-[20px] font-bold">
                <td>&nbsp;</td>
                <td>Tradycyjna rekrutacja</td>
                <td className="text-purple">Współpraca z ReX</td>
                </tr>
                <tr>
                <td className="text-sm font-bold">Średna cena rekrutacji</td>
                <td>20 000 PLN</td>
                <td>7 000 PLN</td>
                </tr>
                <tr>
                <td className="text-sm font-bold">Liczba kandydatów</td>
                <td>3 do 5</td>
                <td>8 do 10</td>
                </tr>
                <tr>
                <td className="text-sm font-bold">Średni czas rekrutacji</td>
                <td>4 tygodnie</td>
                <td>14 dni</td>
                </tr>
                <tr>
                <td className="text-sm font-bold">Zasięg</td>
                <td>Zależnie od biura</td>
                <td>Nieograniczony zasięg</td>
                </tr>
                </tbody>
                </table>
                <div className="hidden md:block">
                <StaticImage quality={70}   src={'../images/PL-poziom.png'} />
                </div>
                <div className="md:hidden">
                <StaticImage quality={70}  src={'../images/PL-pion.png'} />
                </div>
                
         </div>
        </div>
    </section>
  )
}

export default PriceComparison