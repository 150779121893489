import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import ReactTooltip from 'react-tooltip';


const API_PATH = "/api-mail-top.php"
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  email: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .email("Nieprawidłowy format adresu e-mail")
    .required("Pole wymagane"),
  phone: Yup.string()
    .min(5, "Zawartość pola jest zbyt krótka")
    .max(15, "Zawartość pola jest zbyt długa"),
  privacy: Yup.bool().oneOf([true], "Wymagana zgoda."),
  privacy2: Yup.bool().oneOf([true], "Wymagana zgoda."),
})

export default class ContactFormTop extends Component {


  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      position: "",
      region: "",
      sallary: "",
      description: "",
      mailSent: false,
      error: null,
      privacy: false,
      privacy2: false,
      source: this.props.source,
    }
  }

  handleFormSubmit = e => {
    // e.preventDefault()
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
        })
        window.fbq('track', 'Lead');
        window.dataLayer.push({
          event: "conversion",
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }


  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            message: "",
            position: "",
            region: "",
            sallary: "",
            description: "",
            privacy: false,
            privacy2: false,
            source: this.state.source,
          }}
          validationSchema={ValidationSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <Form className="">
              <div>
              </div>
              <h3 className="text-2xl font-bold text-dark-gray"> Zleć rekrutację pracowników do <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReX!</span></h3>
              <div className={this.props.formState ? "grid grid-cols-1 gap-10" : "grid md:grid-cols-2 md:gap-20"}>
                <div>
                  <div className="mb-10">
                    
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="name" placeholder="Imię i nazwisko"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="name"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="email" placeholder="Adres e-mail*"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="email"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="phone" placeholder="Numer telefonu"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="phone"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none resize-none" type="text" as="textarea" name="message" placeholder="Kogo mamy dla Ciebie zrekrutować?" rows="4"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="message"
                          component="span"
                        />
                    </div>
                    </div>
                </div>
                <div id="hidden-fields" className={this.props.formState ? "hidden" : "" }>
                <div className="mb-10">
                    
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="position" placeholder="Stanowisko"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="position"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="region" placeholder="Region rekrutacji"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="region"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="sallary" placeholder="Miesięczne wynagrodzenie brutto"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="sallary"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none resize-none" type="text" as="textarea" name="description" placeholder="Opis stanowiska" rows="4"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="description"
                          component="span"
                        />
                    </div>
                    </div>
                    
                </div>

                <div className="privacy-policies -mt-10">
                    <div>
                      <label
                        className="text-dark-gray relative pl-7 block text-sm mb-4"
                        htmlFor="privacy"
                        data-tip="Niniejszym wyrażam zgodę na przetwarzanie moich danych osobowych przez Antal sp. z o.o. z siedzibą w Warszawie (02-566), ul. Puławska 2, wpisaną do rejestru przedsiębiorców pod numerem KRS: 0000825336 w celu obsługi zapytania ofertowego. <br>Równocześnie oświadczam, że: <br>1. Zdaję sobie sprawę, że podanie moich danych jest dobrowolne, zaś podstawą tego przetwarzania jest wyrażenie przeze mnie zgody; <br>2. Zdaję sobie sprawę, że mam prawo wycofania zgody w dowolnym momencie; <br>3. Zdaję sobie sprawę, że moje dane osobowe będą przetwarzane do momentu zakończenia obsługi zapytania ofertowego, na które wyrażona została przeze mnie zgoda lub do momentu odwołania przedmiotowej zgody, z tym zastrzeżeniem, że po zakończeniu obsługi zapytania ofertowego bądź odwołaniu zgody dane mogą być dalej przetwarzane przez okres przedawnienia roszczeń przysługujących administratorowi jak i tych przysługujących podmiotowi, którego dane dotyczą, przeciwko administratorowi. <br>4. Przyjąłem do wiadomości, że wycofanie zgody może nastąpić poprzez skierowanie wiadomości e-mail na adres: mail@antal.pl;"
                      >
                        Zgoda na przetwarzanie danych osobowych  <span className="ml-3 bg-neutral-300 h-4 w-4 rounded-full inline-flex justify-center items-center text-white font-serif">i</span>
                      <Field
                        type="checkbox"
                        id="privacy"
                        name="privacy"
                        className="absolute left-0 top-0"
                      />
                        <ErrorMessage
                          className="error-msg text-xs"
                          name="privacy"
                          component="span"
                        />
                      </label>
                      <ReactTooltip arrowColor={"#fff"} html={true} className="policy-tooltip"/>
                    </div>
                    <div>
                      <label
                        className="text-dark-gray relative pl-7 block text-sm mb-5"
                        htmlFor="privacy2"
                        data-tip="Wyrażam zgodę na otrzymywanie od Antal sp. z o. o. za pomocą środków komunikacji elektronicznej informacji handlowej w rozumieniu ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną (Dz. U. 2013 poz. 1422) jak również na użycie przez Antal sp. z o. o. urządzeń końcowych oraz automatycznych systemów wywołujących w rozumieniu ustawy z dnia 16 lipca 2004 roku Prawo telekomunikacyjne (Dz. U. 2014 poz. 243). Oświadczam, że zdaję sobie sprawę, że wyrażanie zgody jest dobrowolne. Przyjąłem do wiadomości, że wycofanie zgody może nastąpić poprzez skierowanie wiadomości e-mail na adres: mail@antal.pl"
                      >
                        Zgoda na otrzymywanie informacji handlowej <span className="ml-3 bg-neutral-300 h-4 w-4 rounded-full inline-flex justify-center items-center text-white font-serif">i</span>
                      <Field
                        type="checkbox"
                        id="privacy2"
                        name="privacy2"
                        className="absolute left-0 top-0"
                      />
                        <ErrorMessage
                          className="error-msg text-xs"
                          name="privacy2"
                          component="span"
                        />
                      </label>
                      <Field
                        type="hidden"
                        id="source"
                        name="source"
                        value={this.props.source}
                      />
                    </div>
                    </div>
              </div>
              
              <div className="flex justify-end py-10">
              <button
                className="bg-teal text-dark-gray text-sm font-bold px-10 pt-4  py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple"
                type="submit"
                disabled={isSubmitting}
              >
                Wyślij <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-1 w-3 h-3 inline-block">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" /> </svg>
              </button>
              </div>
              
            </Form>
          )}
        </Formik>
      )
    } else {
      return (
        <form className="py-24 text-center text-dark-gray">
          <h3 className="text-3xl mb-5">Dziękujemy!</h3>
          <p className="text-xl mb-12">Wiadomość została wysłana.</p>
        </form>
      )
    }
  }
}
