import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Efektywna from '../images/about-icons/efektywna.svg'
import Tansza from '../images/about-icons/tansza.svg'
// import Szybsza from '../images/about-icons/szybsza.svg'
import Nieograniczona from '../images/about-icons/nieograniczona.svg'


const About = () => {
  return (
    <section id="o-rex" className="pb-40">
        <div className="max-w-7xl mx-auto grid md:grid-cols-2 pt-20 ">
            <div className="z-20 relative ">
                <h2 className="text-4xl font-bold pb-12">O <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReX</span></h2>
                <p className="text-gray-900 font-light mb-14">
                ReX to innowacyjna platforma crowdstaffingowa, która łączy klientów, rekruterów i kandydatów. ReX Rekruterzy zrealizują nawet najbardziej niestandardowe procesy rekrutacyjne. Odpowiadamy na potrzeby klientów i udowadniamy, że rekrutacja jeszcze nigdy nie była tak prosta!
                </p>
                
                <a href="#top" className="bg-teal px-10 py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline-block text-center hover:bg-purple hover:text-white hover:shadow-purple mb-20">Rozpocznij rekrutację</a>

                <h3 className="text-3xl font-bold pb-12 text-dark-gray">Rekrutacja z <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReX</span> jest</h3>
                <div className="grid md:grid-cols-2 gap-10">
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../images/about-icons/efektywna.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Efektywna</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">Projekty są realizowane przez rekruterów znających branżę klienta i lokalizację, dzięki czemu dostarczają dopasowanych kandydatów.</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../images/about-icons/tansza.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Tańsza</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">Koszt rekrutacji jest mniejszy średnio o połowę, a model rozliczeniowy korzystny dla klienta, który płaci tylko za zatrudnionych kandydatów.</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-16 mr-5" src={'../images/about-icons/szybsza.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Szybsza</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">Zrzeszamy setki rekruterów, od razu przystępujemy do działania i szybko dostarczamy pierwszych kandydatów.</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../images/about-icons/nieograniczona.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Nieograniczona</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">ReX Rekruterzy pracują w wielu różnych lokalizacjach w kilku krajach, dzięki czemu mają szerokie dotarcie do kandydatów.</p>
                    </div>
                </div>    
            </div>
            <div className="flex justify-center items-center relative z-20 m">
                <div className="mt-20 shadow-3xl  bg-white text-center p-10 md:m-20 relative z-10">
                    <StaticImage className="w-36 mb-12" src={'../images/logo-antal-color.svg'} alt="" />
                    <h3 className="text-purple font-bold uppercase text-lg mb-5">Powered by Antal!</h3>
                    <p className="text-gray text-lg">Za platformą ReX stoi siła marki Antal. To ponad 25 lat doświadczenia, najlepsi eksperci i gwarancja najwyższej jakości.</p>
                </div>
            </div>
        </div>
        
    </section>
  )
}

export default About