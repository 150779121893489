import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Clients = () => {
  let settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1300,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
          responsive: [
              {
                  breakpoint: 800,
                  settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      infinite: true,
                  },
              },
              ]
    };

  return (
    <section id="zaufali-nam" className="relative overflow-visible object-none py-24 ">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-center text-3xl font-bold text-dark-gray mb-20">Oni już doświadczyli <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReXwolucji</span></h2>
            {/* <div className="grid grid-cols-6 text-center py-10 gap-10"> */}
            <Slider {...settings} className="relative -mx-5">
              <StaticImage src={'../images/clients/avon-logo.png'} />
              <StaticImage src={'../images/clients/bta-logo.png'} />
              <StaticImage src={'../images/clients/daftcode.png'} />
              <StaticImage src={'../images/clients/gft-logo.png'} />
              <StaticImage src={'../images/clients/gngroup-logo.png'} />
              <StaticImage src={'../images/clients/ifa-logo.png'} />
              <StaticImage src={'../images/clients/mastermind-logo.png'} />
              <StaticImage src={'../images/clients/merlin-logo.png'} />
              <StaticImage src={'../images/clients/nienudno-logo.png'} />
              <StaticImage src={'../images/clients/pitney-bowes-logo.png'} />
              <StaticImage src={'../images/clients/wilmar-logo.png'} />
            </Slider>  
        </div>
            
           
        </section>
        
  )
}

export default Clients